import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Container from "../components/container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import EventsList from "../components/blocks/events-list"

const EventTemplate = ({ data }) => {
  const { content, events } = data

  const seo = {
    title: `Live-Musik mit "${content.artist.name}" | Trödelmarkt Aachener Platz`,
    description: `Genießen Sie ${
      content.artist.genre ? content.artist.genre : `Live-Musik`
    } mit "${
      content.artist.name
    }" auf Düsseldorfs beliebtesten Antik- und Trödelmarkt. ${
      content.dateNice
    } von 11:30-14:30 Uhr. Eintritt frei.`,
    image: content.artist.seoImage.gatsbyImageData.images.fallback.src,
  }

  return (
    <Layout>
      <Helmet>
        <title>{seo.title}</title>
        <meta property="og:title" content={seo.title} />
        <meta name="twitter:title" content={seo.title} />
        <meta name="description" content={seo.description} />
        <meta property="og:description" content={seo.description} />
        <meta name="twitter:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        <meta name="twitter:image" content={seo.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="article:modified_time"
          content={content.meta.updatedAt}
        />
        <meta
          property="article:published_time"
          content={content.meta.firstPublishedAt}
        />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/Tr%C3%B6delmarkt-Aachener-Platz-157866367568023/"
        />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Trödelmarkt Aachener Platz" />
      </Helmet>
      <div className="pt-5 pb-20 md:pt-10 md:pb-40">
        <Container>
          <article className="max-w-3xl">
            <header className="border-b border-opal-600 pb-5 md:pb-10">
              <p>
                <time dateTime={content.datetime} className="">
                  {content.dateNice}
                </time>
              </p>
              <h1 className="mt-5 text-3xl font-black leading-none md:text-4xl">
                {content.artist.name}
              </h1>
              <div className="mt-5">
                <div className="-m-2 flex">
                  <div className="p-2">
                    <p>11:30-14:30 Uhr</p>
                  </div>
                  <div className="p-2">
                    <p>{content.artist.genre}</p>
                  </div>
                  <div className="p-2">
                    <p>Eintritt frei</p>
                  </div>
                </div>
              </div>
            </header>
            <div className="mt-5 md:mt-10">
              <GatsbyImage
                image={getImage(content.artist.image)}
                alt={content.artist.image.alt || ""}
              />
            </div>
            {content.artist.description && (
              <div
                dangerouslySetInnerHTML={{ __html: content.artist.description }}
                className="rich-text mt-5 md:mt-10"
              />
            )}
            {content.artist.url && (
              <div className="mt-5 md:mt-10">
                <a
                  href={content.artist.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-highlight"
                >
                  Zur Band Website
                </a>
              </div>
            )}
          </article>
        </Container>
        <section className="mt-20 md:mt-40">
          <EventsList limit={5} excludeId={content.id} events={events} />
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query EventTemplateQuery($id: String!, $currentDate: Date!) {
    content: datoCmsEvent(id: { eq: $id }) {
      id
      datetime
      dateNice: datetime(formatString: "dddd, D.M.YYYY", locale: "de")
      artist {
        name
        genre
        description
        url
        image {
          alt
          gatsbyImageData(
            width: 768
            placeholder: BLURRED
            forceBlurhash: false
            imgixParams: {
              auto: "compress"
              crop: "faces, entropy"
              fit: "crop"
              w: "768"
              h: "432"
              duotone: "151515,FFFFFF"
            }
          )
        }
        seoImage: image {
          gatsbyImageData(
            width: 1200
            placeholder: BLURRED
            forceBlurhash: false
            imgixParams: {
              auto: "compress"
              crop: "faces, entropy"
              fit: "crop"
              w: "1200"
              h: "630"
              duotone: "151515,FFFFFF"
            }
          )
        }
      }
      meta {
        firstPublishedAt
        updatedAt
      }
    }
    events: allDatoCmsEvent(
      filter: { datetime: { gte: $currentDate } }
      sort: { order: ASC, fields: datetime }
    ) {
      edges {
        node {
          id
          dateSlug: datetime(formatString: "yyyy-MM-DD")
          dateNice: datetime(formatString: "dd, D.M.yyyy", locale: "de")
          artist {
            name
            slug
            genre
            image {
              gatsbyImageData(
                width: 1024
                placeholder: BLURRED
                forceBlurhash: false
                imgixParams: {
                  auto: "compress"
                  crop: "faces, entropy"
                  fit: "crop"
                  w: "1024"
                  h: "576"
                }
              )
            }
          }
        }
      }
    }
  }
`

export default EventTemplate
