import React, { useState } from "react"
import { Link, graphql } from "gatsby"

import Container from "../container"

const Event = ({ dateNice, dateSlug, artist }) => {
  const url = `/musikprogramm/${dateSlug}-${artist.slug}/`

  let meta = []
  meta.push(`11:30-14:30 Uhr`)
  artist && artist.genre && meta.push(`${artist.genre}`)
  meta.push(`Eintritt frei`)

  return (
    <article>
      <Link
        to={url}
        className="transition-background flex items-center py-6 duration-100 hover:bg-opal-400 md:px-6 lg:p-8"
        aria-label={artist.name}
      >
        <div className="w-full md:flex md:items-baseline">
          <div className="md:w-1/4">
            <time dateTime={dateSlug}>{dateNice}</time>
          </div>
          <div className="mt-2 md:mt-0 md:w-3/4">
            <h3 className="text-2xl font-black leading-none sm:text-3xl lg:text-4xl">
              {artist.name}
            </h3>
            <div className="mt-2 lg:mt-3">{meta.join(" | ")}</div>
          </div>
        </div>
        <div className="ml-4 flex-shrink-0 md:ml-8">
          <svg viewBox="0 0 64 64" className="h-12 md:h-16">
            <circle cx="32" cy="32" r="32" fill="#2C2B29" />
            <g
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="white"
              fill="none"
            >
              <path d="m37 25 7 7-7 7" />
              <path d="m43 32h-23" />
            </g>
          </svg>
        </div>
      </Link>
    </article>
  )
}

const EventsList = ({ title, limit, excludeId = ``, events }) => {
  const eventsCleaned = events.edges
    .map(({ node }) => node)
    .filter((event) => event.id !== excludeId)

  const totalPages = Math.ceil(eventsCleaned.length / limit)
  const [currentPage, setCurrentPage] = useState(1)

  return totalPages !== 0 ? (
    <Container>
      {title && (
        <h2 className="mb-5 text-xl font-black leading-none md:mb-10 md:text-2xl">
          {title}
        </h2>
      )}
      <ul className="divide-y divide-opal-600 border-t border-b border-opal-600">
        {eventsCleaned.slice(0, limit * currentPage).map((event) => (
          <li key={event.id} className="leading-tight">
            <Event {...event} />
          </li>
        ))}
      </ul>
      {currentPage !== totalPages && (
        <div className="mt-8 flex justify-center ">
          <button
            onClick={() => {
              setCurrentPage(currentPage + 1)
            }}
            className="link-highlight"
          >
            Weitere Veranstaltungen anzeigen
          </button>
        </div>
      )}
    </Container>
  ) : null
}

export const query = graphql`
  fragment EventsList on DatoCmsPage {
    blocks {
      ... on DatoCmsBlockEventsList {
        id
        model {
          apiKey
        }
        title
        limit
      }
    }
  }
`

export default EventsList
